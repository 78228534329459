module.exports =[
["0x4ab537993ba48a3d57f3b7e74405fc4f5fad36cb",2],
["0x4ab537993ba48a3d57f3b7e74405fc4f5fad36cc",2],
["0x0c36e89d9c10ec66d39cef81f0599921aa44f1de",5],
["0x7aa07bdd8955833f45a946a735381131610ddd64",2],
["0x3e147383d413425aa9ac2665a8ec449b35b4010d",2],
["0x558fd4e0ebfd3b7c3aef94b646924f410e729c2d",2],
["0x6b3b5eee8a7096110e8cb63be91bd6f37ad3b219",2],
["0xb23eb4efb463bd20cbfe59a9e2a18d8fbead9c31",2],
["0x3e31bda6f6f74a59e8a3b90836727b31e22af948",2],
["0x69c1f3471ac09760077d71a3a60125a678b507d2",2],
["0x08b35d6cffff52e3d619140ad88ed43d9c6e66d8",2],
["0xa2f89317c18ad6ef58f5e0e49144493c0a3affc6",2],
["0x6c19b30c28f6da3cca364749726348d300d3ccd6",2],
["0xdb59513cce357c6376d5b8f6f750838bf4ef0c60",2],
["0xa3cc3cdba80076225ed1243d386d039a6dc23aa6",2],
["0x45590539f89d2ff90d5ba1125c8ddb469e936db2",2],
["0x79b8e42e5e30cdd013d117142ec54c575ca0402f",2],
["0x45150fa60c1c7c15d55a83eab93687c52ce435ce",2],
["0xed5eafa53d6bcbb9d2a34cafee6a666187d49066",2],
["0x48f51d6c5ff799eb65e0f02e2fe2959aea298513",2],
["0xed163479cd94836d1d9677510730368abc5c0073",2],
["0xb64ee4fd9eb8d4c2b0c7d54b41ce21e5e9267e7f",2],
["0xb6bc6fcaf9eb84f9d81237ad72534d58294d0b89",2],
["0xc30758f7226fd4f907249cc1849ba3454dcaca65",2],
["0xbd75d56c9c3b146b9b1f23afb898680d48641850",2],
["0xafc10938692f03fe3f949b52be5b48459389f48c",2],
["0xef8226905fbe8f6c2ea484b197d10f7cdf8d3cd6",2],
["0x92b354137afc70be4b430d54c5e65a9f13fd7d60",2],
["0x990d97ac72cf56b878314c6d3cb1d53fbac90264",2],
["0xec37e60a84708ee22e8cd275c8c6baac6a8421f7",2],
["0xf214c2b957fb06a9897d8b49d049b7519b19af82",2],
["0xabd892dd47d1497fa76b77413ee3c56890bd00cc",2],
["0x46eba9458af729a5ad3b869f27f2882fb72d7017",2],
["0x53aa192df1c7bce497170df51011efa8dcabf88c",2],
["0x37dcae93357b0e2b439b41fa0f8bc4615c4aaf20",2],
["0xfa2b5b90ee2d61c4f9df06db4b1d851239cf5ca6",2],
["0x6e4d867b0b9da23d0b15e28d1ddc8f382e9e3a5b",2],
["0xf696f248ece1ae3d8a83eaaca9027e257551eaba",2],
["0xdbda3833be54a3286e518a74a116c7c20704f473",2],
["0xdd1253c5484b655f8274e72560301a57928f9e58",2],
["0x8c51c16585fb6abd11f0dcd1c43725b227328861",2],
["0x7f0dcb0c9f933440b8d7386d01e10047737b13d6",2],
["0x034b0cbd4712ce31ece157e6bd5ed6dd2820b135",2],
["0x7f844e8f922dd73629996cd04979f16a0a408007",2],
["0xe2cec2cc859827b3dbace7b92e18f83c3e14270d",2],
["0x3f542620ca97f7559d1b46c886bce05f9bc0b169",2],
["0x3c659c97c9a1b209e7d879eeb269850a6a867c7f",2],
["0x272217c3b6a836445ceadba7990faa183a4e5832",2],
["0xfcce322a8ff9c3d43c8f81f711b5d58d08c6fc11",2],
["0xfba206e73709f614e5a85aff27a98692d4f3c579",2],
["0x03762c4478518fbf036ed6062fe9035d2e6d0670",2],
["0xd209ff17fcc25594359442c1bbc70d00004ce84f",2],
["0xd57249dda4db897aad0621ae362e66e58492845e",2],
["0x38a243c843c31c05ff034ea899d82de94c696776",2],
["0x040c12e4bda4b4dc46bbebd45c148a2acf3968e0",2],
["0x0a633935262518c8f4bdccce0aecffedbb1e88d7",3],
["0x229286806354df9f97304623315c81408cd5d597",4],
["0x3f5918bc4cf4894ea0a8d1d544aeff4f59f8c1a6",4],
["0x128ae50be02cc9730a2b009ecdd0d9c551819d49",4],
["0x27a2adc97246ec7580388bf20d19f889d56388e9",4],
["0x281e6148d1ce38575ccb19447f283a220b48ceb3",4],
["0x4fdeaeb627764e38e80c9819de5058af57dbdbac",4],
["0x644cfe9980c4ecd09a3120dcef502dc092ccf51f",4],
["0x18fc46bfe8b633948a2775797c09386ad5b6c428",4],
["0x36d6c8269139b5d54d7cb63cf2bbc3a482c1bfaa",4],
["0x79b8e17396932a6a94b2bd77a78efe502faced5f",4],
["0x5d61f268eef978c27d56fc2722111481e6ae21ef",4],
["0xf756a2dae5e5d34185e6f1e57efe02bd7249d617",4],
["0x3a9eece65e993c3637d2ed8e15643e3679056773",4],
["0xf7ff484489445561ddf458200443348509461608",4],
["0x121716a860c745c73dc3d8fe7eb43389751cdc3c",4],
["0xf9074600618b7f1c2ddefce8f38da11d4ac6567d",4],
["0x6dc3120ed771d18682d7e90d30311fa2a1069bb8",4],
["0x938bfd4241872f1b51b23da66ad06cfd57272335",4],
["0x534ed1263b2a67e3886fb06bb1d186f166eb7493",4],
["0xd9bee9d2fd55999f0be82e194278453f0ddb37f1",4],
["0x95ee8b32aac20a4ca806d801774fc5e31fece7d4",4],
["0x2fc866646e15868e67a57c6474e9d14c8d7e7318",5]
];