import Routers from './Routers';

const App = () => {
  return (
    <div className="App">
      <Routers/>
    </div>
  );
}

export default App;